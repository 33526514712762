//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#3699FF; // Bootstrap variable
$primary-hover:    									#187DE4; // Custom variable
$primary-light:    									#E1F0FF; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable


$gray-800: 											#101010 !default;
// $font-family-base:            						Poppins, Helvetica, "sans-serif" !default;